<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>

          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendRole.roleName") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  :placeholder="$t('edpBackendCommon.placeholder')"
                  v-model="searchParams.roleName"
                  clearable
                />
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="clearSearchParams">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="pageInit(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>

          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="createRow">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >

                <el-button size="small" @click="refreshCache">
                  {{ $t("edpBackendRole.refreshCache") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                prop="roleName"
                :label="$t('edpBackendRole.roleName')"
              ></el-table-column>

              <el-table-column
                prop="roleCode"
                :label="$t('edpBackendRole.roleCode')"
              >
              </el-table-column>

              <el-table-column
                prop="createTime"
                :label="$t('edpBackendRole.createTime')"
              >
              </el-table-column>

              <el-table-column
                :label="$t('edpBackendRole.control')"
                width="150"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="setRow(scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>

    <el-dialog
      class="edp-backend-dialogEl"
      v-if="dialogVisible"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="500px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item :label="$t('edpBackendRole.roleCode')" prop="roleCode">
          <el-input
            v-model="form.roleCode"
            clearable
            :disabled="dialogType === 'update'"
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('edpBackendRole.roleName')" prop="roleName">
          <el-input v-model="form.roleName" clearable></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('edpBackendRole.setPower')"
          prop="permissionIds"
        >
          <el-select
            v-model="form.permissionIds"
            :placeholder="$t('edpBackendCommon.select')"
            multiple
            clearable
          >
            <el-option
              v-for="item in permissionIdsOptions"
              :key="item.id"
              :label="item.perName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="dialogVisible_submit">{{
          $t("edpBackendCommon.define")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "backendRole",
  data() {
    return {
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: true,

      searchParams: {
        roleName: "",
      },

      dialogTitle: "",
      dialogVisible: false,
      dialogType: "",

      form: {
        id: "",
        roleName: "",
        roleCode: "",
        permissionIds: [],
      },
      permissionIdsOptions: [],

      rules: {
        roleCode: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRole.roleCode"),
            trigger: "blur",
          },
        ],
        roleName: [
          {
            required: true,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRole.roleName"),
            trigger: "blur",
          },
        ],
        permissionIds: [
          {
            required: false,
            message:
              this.$t("edpBackendCommon.placeholder") +
              "" +
              this.$t("edpBackendRole.setPower"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      roleList: "backendRole/roleList",
      roleDetail: "backendRole/roleDetail",
      roleCreate: "backendRole/roleCreate",
      roleUpdate: "backendRole/roleUpdate",
      roleDelete: "backendRole/roleDelete",
      powerList: "backendPower/powerList",
      roleRefresh: "backendRole/roleRefresh",
    }),
    async pageInit(num) {
      this.loading = true;

      if (num) {
        this.currentPage = num;
      }

      let params = Object.assign(
        JSON.parse(JSON.stringify(this.searchParams)),
        {
          current: this.currentPage,
          size: this.pageSize,
        }
      );

      let res = await this.roleList(params);

      this.pageList = res.data.data.records;

      this.pageTotal = res.data.data.total;

      this.loading = false;
    },
    handleCurrentChange(val) {
      this.pageInit(val);
    },
    async refreshCache() {
      let res = await this.roleRefresh();

      if (res.data.success) {
        this.$message({
          type: "success",
          message: this.$t("edpBackendCommon.actionSuccess"),
        });

        this.pageInit(1);
      }
    },
    clearSearchParams() {
      for (var key in this.searchParams) {
        this.searchParams[key] = "";
      }

      this.pageInit(1);
    },
    async createRow() {
      this.dialogType = "";
      this.dialogTitle = this.$t("edpBackendCommon.add");

      this.form.id = "";
      this.form.roleCode = "";
      this.form.roleName = "";
      this.form.permissionIds = [];

      this.dialogVisible = true;
    },
    async setRow(data) {
      this.dialogType = "update";
      this.dialogTitle = this.$t("edpBackendCommon.edit");

      let res = await this.roleDetail(data.id);

      this.form.id = res.data.data.id;
      this.form.roleCode = res.data.data.roleCode;
      this.form.roleName = res.data.data.roleName;
      this.form.permissionIds = res.data.data.permissionIds || [];

      this.dialogVisible = true;
    },
    async deleteRow(data) {
      this.$confirm(
        this.$t("edpBackendRole.deleteConfirm"),
        this.$t("edpBackendCommon.noticeTitle"),
        {
          confirmButtonText: this.$t("edpBackendCommon.define"),
          cancelButtonText: this.$t("edpBackendCommon.canale"),
        }
      ).then(async () => {
        let res = await this.roleDelete(data.id);

        if (res.data.success) {
          this.$message({
            type: "success",
            message: this.$t("edpBackendCommon.actionSuccess"),
          });

          this.pageInit(1);
        }
      });
    },
    async dialogVisible_submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = {};

          if (this.dialogType === "update") {
            res = await this.roleUpdate(this.form);
          } else {
            res = await this.roleCreate(this.form);
          }

          if (res.data.success) {
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });

            this.dialogVisible = false;

            this.pageInit(1);
          }
        }
      });
    },
  },
  async mounted() {
    this.pageInit();

    let res = await this.powerList({
      current: 1,
      size: 1000,
    });

    this.permissionIdsOptions = res.data.data.records;
  },
};
</script>
